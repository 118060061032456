body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  background: #4C70B9 !important;
}

.navbar-brand img {
  width: 65%;
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: white !important;
}

.navbar-expand-lg .navbar-nav .nav-link:focus, .navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #fffddd !important;
}

.top-nav-collapse .navbar-nav li.active a.nav-link {
  color: #3dd4bb !important;
}

.navbar-expand-lg .navbar-nav .active>.nav-link, .navbar-expand-lg .navbar-nav .nav-link.active, .navbar-expand-lg .navbar-nav .nav-link.open, .navbar-expand-lg .navbar-nav .open>.nav-link {
  color: #3dd4bb !important;
}

.slicknav_btn {
  border-color: #ffffff;
}

.slicknav_menu .slicknav_icon-bar {
  background: #fff;
}

@media (max-width: 767px) {
  .navbar-brand img {
    margin: 0;
  }
}

.img-fluid {
  max-width: 90%;
  height: auto;
}

.tcenter {
  text-align: center;
}

.testimonial-item {
  background: #46464a;
}

#home {
  color: #fff;
  overflow: hidden;
  position: relative;
  background: #f8f9fb;
}

#home .contents {
  padding: 170px 0px 50px;
}

#home .contents h5 {
  font-size: 50px;
}

#home .contents .head-title {
  color: #585b60;
  font-size: 34px;
  line-height: 48px;
  font-weight: 700;
  margin-bottom: 30px;
}
